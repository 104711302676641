ptz-dropdown-option {
  .ptz-dropdown-option {
    list-style: none;
    cursor: pointer;
    display: flex;
    background-color: $petz-color-neutral-white;
    justify-content: space-between;

    a:hover,
    a:focus {
      text-decoration: none;
    }

    &-selected,
    &:hover {
      background-color: $petz-color-neutral-lighter-accent;
    }

    &-a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $petz-font-size-2xs;
      font-weight: $petz-font-weight-regular;
      line-height: $petz-font-lineheight-lg;
      padding: $petz-spacing-xs $petz-spacing-sm;
      color: $petz-color-neutral-black;
      width: 100%;
      background-color: inherit;
      border: none;
      cursor: pointer;

      &:active {
        background-color: $petz-color-neutral-light;

        &~ptz-icon {
          background-color: $petz-color-neutral-light;
        }
      }
    }

    &-div {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }

    &-span {
      color: $petz-color-neutral-dark;
    }

    &-icon {
      &-left {
        margin-right: 5px;
      }

      &-right {
        margin-left: 5px;
        margin-right: 10px;
      }
    }

    &-hide {
      display: none;
    }

    ptz-icon {
      display: flex;
      align-items: center;
    }
  }
}
