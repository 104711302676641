import { Component, Element, Event, EventEmitter, Host, Listen, Prop, State, h } from '@stencil/core';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZDropdownOptionTypes } from './types/ptz-dropdown-option.types';
import { isOnIframe } from '../../utils/utils';

@Component({
  tag: 'ptz-dropdown-option',
  styleUrl: 'ptz-dropdown-option.scss',
  shadow: false,
})
export class PTZDropdownOption {
  /** Referência do elemento html host deste componente */
  @Element() element: HTMLElement;

  /** Valor do option */
  @Prop() value: string;

  /** SubTexto do option */
  @Prop() supportLabel?: string;

  /** Quando o option está selecionado */
  @Prop() selected: boolean = false;

  /** Nome do ícone no option */
  @Prop() iconName?: PTZIconTypes.Name;

  /** Variante do ícone no option */
  @Prop() iconVariant?: PTZIconTypes.Variant;

  /** Nome do ícone no option */
  @Prop() supportIconName?: PTZIconTypes.Name;

  /** Variante do ícone no option */
  @Prop() supportIconVariant?: PTZIconTypes.Variant;

  @State() currentValue: string = '';

  @Event({
    eventName: 'optionSelected',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  optionSelected: EventEmitter<PTZDropdownOptionTypes.OptionSelected>;

  private handleSelectOption(event) {
    const value = event.target.dataset.value ?? this.value;
    this.optionSelected.emit({ value });
  }

  @Listen('getValue', { target: 'document' })
  getCurrentValue(event: CustomEvent<PTZDropdownOptionTypes.GetValue>) {
    const { target, detail } = event;

    if (!(target as HTMLElement).contains(this.element)) return;

    const value = detail.value;
    this.selected = value === this.value;
  }

  @Listen('filterValues', { target: 'document' })
  filterDropdown(event: CustomEvent<PTZDropdownOptionTypes.FilterValues>) {
    const { target, detail } = event;

    if (!(target as HTMLElement).contains(this.element)) return;

    const currentValue = detail.currentValue;
    this.currentValue = currentValue;
  }

  render() {
    const filter = () => {
      return this.value.toLowerCase().includes(this.currentValue.toLowerCase()) ? '' : 'ptz-dropdown-option-hide';
    };

    return (
      <Host>
        <li class={`ptz-dropdown-option ${filter()}`}>
          <a
            role="tab"
            tabindex="0"
            aria-selected="false"
            class="ptz-dropdown-option-a"
            data-value={this.value}
            { ...(isOnIframe() ? { target: "_parent" } : {}) }
            onClick={(event: MouseEvent) => this.handleSelectOption(event)}
          >
            <div class="ptz-dropdown-option-div">
              {this.iconName && this.iconVariant ? <ptz-icon class="ptz-dropdown-option-icon-left" name={this.iconName} variant={this.iconVariant} size="lg"></ptz-icon> : ''}
              <span>{this.value}</span>
            </div>
            <div class="ptz-dropdown-option-div">
              {this.supportLabel ? <span class="ptz-dropdown-option-span">{this.supportLabel}</span> : ''}
              {this.supportIconName && this.supportIconVariant ? (
                <ptz-icon class="ptz-dropdown-option-icon-right" name={this.supportIconName} variant={this.supportIconVariant} size="lg"></ptz-icon>
              ) : (
                ''
              )}
              {this.selected ? <ptz-icon color="primary-brand" name="check-solid" variant="solid" size="md"></ptz-icon> : ''}
            </div>
          </a>
        </li>
      </Host>
    );
  }
}
